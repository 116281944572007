import { CoreMenu } from '@core/types'

export const menu: 

CoreMenu[] = [
  
  {
    id: 'home',
    title: 'Metas',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'recalculoTransacciones',
    title: 'Recálculo Transacciones',
    translate: 'MENU.RECALCULOTRANSACCIONES',
    type: 'item',
    icon: 'layers',
    url: 'RecalculoTransaccionesComponent'
  },
  
  {
    id: 'consultaConcesiones',
    title: 'Concesiones',
    translate: 'MENU.CONSULTACONCESIONES',
    type: 'item',
    icon: 'layers',
    url: 'consultaConcesiones'
  }
  ,
  
  // {
  //   id: 'informePanama',
  //   title: 'Indicadores Panama',
  //   translate: 'MENU.PANAMA',
  //   type: 'item',
  //   icon: 'layers',
  //   url: 'panamaComponent'
  // },
  {
    id: 'ocupacionAsesoras',
    title: 'Ocupacion',
    translate: 'MENU.OCUPACIONASESORAS',
    type: 'item',
    icon: 'layers',
    url: 'ocupacionAsesoras'
  }
  ,
  {
    id: 'acumuladoPt',
    title: 'Detalle Acum',
    translate: 'MENU.CUMULADOPT',
    type: 'item',
    icon: 'bar-chart-2',
    url: 'acumuladoPt'
  }
  ,
  {
    id: 'sample',
    title: 'Detalle Diario',
    translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'bar-chart-2',
    url: 'sample'
  }
  ,
  {
    id: 'horaingreso',
    title: 'Apertura-Cierre',
    translate: 'MENU.HORAINGRESO',
    type: 'item',
    icon: 'watch',
    url: 'horaingreso'
  }
  ,
  {
    id: 'heatmap',
    title: 'Informe por Hora',
    translate: 'MENU.HEATMAP',
    type: 'item',
    icon: 'layers',
    url: 'heatmap'
  },
  {
    id: 'referenciasEspecificas',
    title: 'Voluminizacion',
    translate: 'MENU.REFERENCIAS',
    type: 'item',
    icon: 'layers',
    url: 'referenciasEspecificas'
  },
  {
    id: 'configvoluminizacion',
    title: 'ConfigVoluminizacion',
    translate: 'MENU.CONFIGVOLUMINIZACION',
    type: 'item',
    icon: 'layers',
    url: 'configVoluminizacion'
  }
  ,
  {
    id: 'subirReposPedido',
    title: 'Subir Repos Pedido',
    translate: 'MENU.DATOSREPOSPEDIDO',
    type: 'item',
    icon: 'layers',
    url: 'subirDataReposPedido'
  }
  // {
  //   id: 'consultarReposPedidos',
  //   title: 'Consultar Repos',
  //   translate: 'MENU.CONSULTARREPOSPEDIDOS',
  //   type: 'item',
  //   icon: 'layers',
  //   url: 'consultarReposPedidos'
  // }
  
]
