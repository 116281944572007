import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InfoTiendaDataV } from '../../../mock/tiendasV'
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { DiasMes } from "../../../mock/diasmes";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-referencias-especificas',
  templateUrl: './referencias-especificas.component.html',
  styleUrls: ['./referencias-especificas.component.scss']
})
export class ReferenciasEspecificasComponent implements OnInit {
  contentHeader: { headerTitle: string; actionButton: boolean; breadcrumb: { type: string; links: ({ name: string; isLink: boolean; link: string; } | { name: string; isLink: boolean; link?: undefined; })[]; }; };
  public fromDate: any
  public toDate: any
  model: NgbDateStruct;

  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{}];

  public hoveredDate: NgbDate | null = null;
  public loading = false;
  presupuestomes: any = 0;
  public pageBasic = 1;
  public page = 1;
  public pageSize = 10;
  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  nombretienda: string;
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = 'Faltante';
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  participacionfisico: number = 0;
  participaciondigital: number = 0;
  participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ar";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;
  rol: string;
  mostrarCampo: boolean = true;

  coordinador: string;
  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  codigoarray: any;
  mostrarDetalleTransacciones: boolean = false;
  mostrarDetallePartCreditos: boolean;
  fechaSincronizacionCam: any;
  intervalId: number;
  token: string;
  pTotal: any;
  pendTotal: any;
  excepciones: string[];
  variable: any;
  fechaAux: any;
  fechaAuxFin: any;
  consultaAnterior: number = 0;
  colorTransCredTotal: string;
  colorTransCred: boolean;
  flechaRojaTransCred: boolean;
  colorTranskai: boolean;
  flechaRojaTranskai: boolean;
  colorCumplimTotal: boolean;
  flechaRojaCumplimTotal: boolean;
  colorcrecCantCedValid: boolean;
  flechaRojacrecCantCedValid: boolean;
  colorCcrecim: boolean;
  flechaverdeCcrecim: boolean;
  flecharojaCcrecim: boolean;
  mostrado: boolean = false;
  counte: any;
  acumPptoVPT: any;
  dataEnviar: { pais: string; fechaInicio: string; fechaFin: string; idAlmacen: any; };
  resultado: any;
  resultado1: any;
  longitud: any;
  ventas: any;
  estienda: boolean = false;
  datosAplanados: any;
  ventasPorAnioEscenario: any = {};
  unidadesPorAnioEscenario: any = {};
  resumenVentas: any = [];
  resumenUnidades: any = [];
  ventaTotalRango: number;
  totalUnidadesRango: number;
  pptoVentasPorAnioEscenario: any = {};
  referenciasSubidas: any;
  loading1: boolean;
  referenciasUnicas: unknown[];
  listaObjetos: any;
  referenciasPorEscenario: {[key: string]: string[]} = {};
  pptoUnidadesPorAnioEscenario:any= {};
  referenciasMostrar: string[];
  totalValorActual: number;
  totalPresupuesto: number;
  totalValorAnterior: number;
  totalCumplimiento: number;
  totalCrecimiento: number;
  totalPendiente: number;
  totalParticipacion: number;
  totalUnidadesValorActual: number;
  totalUnidadesPresupuesto: number;
  totalUnidadesValorAnterior: number;
  totalUnidadesCumplimiento: number;
  totalUnidadesCrecimiento: number;
  totalUnidadesPendiente: number;
  totalUnidadesParticipacion: number;

  constructor(private modalService: NgbModal, private diamess: DiasMes, private afMessaging: AngularFireMessaging, private calendar: NgbCalendar, public excelService: ExcelService, private router: Router, public formatter: NgbDateParserFormatter, private inforTiendas: InfoTiendaDataV, private service: ServiceService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  ngOnInit() {
    this.vista = 'Ac'
    // // if(sessionStorage.getItem('responsable')=='Lineth Yanubis Sanchez Gaitan'){
    // //   this.router.navigate(['/panama/panama.component.html'])
    // //   return
    // // }
    this.excepciones = ["Julian Alberto Navarro Garcia", "Leydi Milena Peña Sepulveda", "Mariana Ossa Sanchez", "Juan Carlos Gallego Elejalde", "Melissa Castrillon Cano", "Jose Mario Tamayo Lopera", "Juan Carlos Maya Arbelaez", "Carlos Ignacio Urrea", "Mauricio Andres Cardona Suarez", "Ana Lucia Hernandez Santa", "Alejandro Gonzalez Vasquez", "Maria Camila Cardona Arroyave", "Maria Cristina Londoño Cruz", "David  Gomez Patiño", "Soraya  Bedoya Mejia", "Margarita Maria Mazo Franco", "Luis Guillermo Maya Arbelaez", "Sergio Alexander Villa Cano"];
    this.rol = sessionStorage.getItem('rol');
    if (this.rol == "null" || this.rol == "") {
      this.estienda = false
    } else {
      this.estienda = true
    }
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth() + 1
    // var day = d.getDate();
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    // this.fechainicioStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    // this.fechafinStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.coordinador = sessionStorage.getItem('responsable');
    this.mostrarVentas = false
    this.mostrarIndicadores = false
    this.mostrarPotenciales = false
    this.codigoTienda = null;
    this.agenciaTienda = null;
    this.formatoTienda = null;
    this.ciudad = null;

    this.nombretienda = sessionStorage.getItem('tienda');
    if (this.nombretienda == null) {
      this.router.navigate(['../pages/authentication/auth-login-v2/auth-login-v2.component.html'])
      return
    }

    if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Responsable == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }


    }
    else if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {

      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Gerente == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }

    }
    else
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll;
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Voluminizacion',
            isLink: false
          }
        ]
      }
    }
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterGer()
    }
    else
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.filterResp()

      } else {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.fillFilter(this.inforTiendas.infoTienda);
      }
  }
  private fillFilter(infotienda: any) {


    var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });
    var arregloAgencia = [];
    agencia.map((a) => {
      if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)

        arregloAgencia.push(a);
    });
    // console.log(arregloAgencia);


    this.agencias = [...new Set(arregloAgencia)];
    this.formatos = [...new Set(infotienda.map(x => x.Formato))];
    this.ciudades = [...new Set(infotienda.map(x => x.CIUDAD))];
    this.responsables = [...new Set(infotienda.map(x => x.Responsable))];
    this.tiendas = [...new Set(infotienda.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];
    this.vista = this.vista;

  }
  seleccionarAnterior() {
    this.vista = 'Ac';
    this.consultaAnterior = 1
    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0") == "01") {
      mesActual = 13
    }
    this.diamess.mes.map(x => {
      if (x.Mes == (mesActual - 1).toString().padStart(2, "0")) {
        if (mesActual == 13) {
          this.fechainicioStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = (this.fromDate.year - 1).toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        } else {
          this.fechainicioStr = this.fromDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = this.toDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = this.toDate.year.toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        }

      }
    })

    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  limpiarFiltPv() {
    this.codigoTienda = null;
  }
  buscar() {
    this.loading = true
    if (this.codigoTienda != null) {
      if(this.codigoTienda.length != 0){
        const tiendas = this.codigoTienda.map(r => r.Codigo)
        var data = {
          fechaInicial: this.fechainicioStr,
          fechaFinal: this.fechafinStr,
          puntosVenta: tiendas.join(',')
        }
        var dataEnviar = {
          vista: "Ac",
          fechaInicio: this.fechainicioStr,
          fechaFin: this.fechafinStr,
          tiendas: this.codigoTienda.map(r => r.Codigo)
        }
        var dataFechas = {
          fechaInicial: this.fechainicioStr,
          fechaFinal: this.fechafinStr
        }
      }else{
        const tiendas = this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)

      var data = {
        fechaInicial: this.fechainicioStr,
        fechaFinal: this.fechafinStr,
        puntosVenta: tiendas.join(',')
      }
      var dataEnviar = {
        vista: "Ac",
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
      }
      var dataFechas = {
        fechaInicial: this.fechainicioStr,
        fechaFinal: this.fechafinStr
      }
      }
      
    }
    else {
      const tiendas = this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)

      var data = {
        fechaInicial: this.fechainicioStr,
        fechaFinal: this.fechafinStr,
        puntosVenta: tiendas.join(',')
      }
      var dataEnviar = {
        vista: "Ac",
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
      }
      var dataFechas = {
        fechaInicial: this.fechainicioStr,
        fechaFinal: this.fechafinStr
      }
    }
    this.service.getVoluminizacionByDate(data).then(x => {
      console.log(x)
      this.datosAplanados = x.flatMap(dato =>
        dato.data.flatMap(almacen =>
          almacen.escenarioData.flatMap(escenario =>
            escenario.calculos
          )
        )
      );
      console.log(this.datosAplanados)
      this.service.RDLIndicadoresAcumuladosMesMuchasTiendas(dataEnviar).then(result => {
        console.log('consultoVentatotal')
        this.loading = false
        this.ventaTotalRango = parseFloat(result.meses[0].totalVentasFisicas) + parseFloat(result.meses[0].totalVentasVirtuales) - parseFloat(result.meses[0].totalDevoluciones);
        this.totalUnidadesRango = parseFloat(result.meses[0].cantidadArticulosDigitales) + parseFloat(result.meses[0].cantidadArticulosFisicos)
        this.service.getVoluminizacionByDates(dataFechas).then(res => {
          this.referenciasSubidas = res
          this.referenciasSubidas.forEach(obj => {
            if (!this.referenciasPorEscenario[obj.escenario]) {
              this.referenciasPorEscenario[obj.escenario] = [];
            }
            const referencias = obj.referencias.split(', ');
            const referenciasUnicas = new Set([...this.referenciasPorEscenario[obj.escenario], ...referencias]);
            this.referenciasPorEscenario[obj.escenario] = Array.from(referenciasUnicas);
          });
          this.longitud = res.length;
        })
        this.procesarDatos(this.datosAplanados);
      })

    })

  }

  procesarDatos(datosaplanados) {
    this.ventasPorAnioEscenario = {}
    this.unidadesPorAnioEscenario = {}
    this.pptoVentasPorAnioEscenario = {}
    this.pptoUnidadesPorAnioEscenario = {}

    this.resumenVentas = []
    this.resumenUnidades = []
    this.totalValorActual = 0;
    this.totalPresupuesto = 0;
    this.totalValorAnterior = 0;
    this.totalCumplimiento = 0;
    this.totalCrecimiento = 0;
    this.totalPendiente = 0;
    this.totalParticipacion = 0;

    this.totalUnidadesValorActual = 0;
    this.totalUnidadesPresupuesto = 0;
    this.totalUnidadesValorAnterior = 0;
    this.totalUnidadesCumplimiento = 0;
    this.totalUnidadesCrecimiento = 0;
    this.totalUnidadesPendiente = 0;
    this.totalUnidadesParticipacion = 0;
    datosaplanados.forEach((dato) => {
      const fecha = new Date(dato.fecha);
      const año = fecha.getFullYear();
      const { escenario, baseDcto, unidades, presupuesto = 0,presupuestoUnidades=0 } = dato;
      const clave = `${año}-${escenario}`;

      // Asegurándonos de que cada clave esté inicializada correctamente
      if (!this.ventasPorAnioEscenario[clave]) {
        this.ventasPorAnioEscenario[clave] = 0;
      }
      if (!this.unidadesPorAnioEscenario[clave]) {
        this.unidadesPorAnioEscenario[clave] = 0;
      }
      if (!this.pptoVentasPorAnioEscenario[clave]) {
        this.pptoVentasPorAnioEscenario[clave] = 0;
      }
      if (!this.pptoUnidadesPorAnioEscenario[clave]) {
        this.pptoUnidadesPorAnioEscenario[clave] = 0;
      }

      // Ahora sumamos con seguridad, sabiendo que las claves están inicializadas
      this.ventasPorAnioEscenario[clave] += baseDcto;
      this.pptoVentasPorAnioEscenario[clave] += presupuesto
      this.pptoUnidadesPorAnioEscenario[clave]+=presupuestoUnidades
      this.unidadesPorAnioEscenario[clave] += unidades;

    });
    const anios = new Set<number>();
    const escenarios = new Set<string>();
    const anios1 = new Set<number>();
    const escenarios1 = new Set<string>();
    // console.log('Ventas por año y escenario:', this.ventasPorAnioEscenario);
    // console.log('Unidades por año y escenario:', this.unidadesPorAnioEscenario);
    Object.keys(this.ventasPorAnioEscenario).forEach(key => {
      const [anio, escenario] = key.split('-');
      anios.add(parseInt(anio));
      escenarios.add(escenario);
    });
    Object.keys(this.unidadesPorAnioEscenario).forEach(key => {
      const [anio, escenario] = key.split('-');
      anios1.add(parseInt(anio));
      escenarios1.add(escenario);
    });
    const anioActual = Math.max(...Array.from(anios));
    const anioAnterior = anioActual - 1;
    const anioActual1 = Math.max(...Array.from(anios1));
    const anioAnterior1 = anioActual1 - 1;
    escenarios.forEach(escenario => {
      const valorActual = this.ventasPorAnioEscenario[`${anioActual}-${escenario}`] || 0;
      const valorAnterior = this.ventasPorAnioEscenario[`${anioAnterior}-${escenario}`] || 0;
      const presupuesto = this.pptoVentasPorAnioEscenario[`${anioActual}-${escenario}`] || 0;
      const crecimiento = valorAnterior > 0 ? ((valorActual - valorAnterior) / valorAnterior * 100).toFixed(2) : 'N/A';
      const participacion = (valorActual * 100) / this.ventaTotalRango;
      const cumplimiento = (valorActual * 100) / presupuesto;
      const pendiente = presupuesto - valorActual;

      this.resumenVentas.push({ escenario, valorActual, valorAnterior, crecimiento, participacion, presupuesto, cumplimiento, pendiente });

      // Calculamos los totales
      this.totalValorActual += valorActual;
      this.totalPresupuesto += presupuesto;
      this.totalValorAnterior += valorAnterior;
      this.totalCumplimiento += cumplimiento;
      this.totalCrecimiento += parseFloat(crecimiento);
      this.totalPendiente += pendiente;
      this.totalParticipacion += participacion;
    });

    // Ajustamos los totales de porcentaje
    this.totalCumplimiento = this.totalCumplimiento / this.resumenVentas.length;
    this.totalCrecimiento = this.totalCrecimiento / this.resumenVentas.length;
    this.totalParticipacion = (this.totalValorActual*100) / this.ventaTotalRango;
    escenarios1.forEach(escenario => {
      const valorActual = this.unidadesPorAnioEscenario[`${anioActual}-${escenario}`] || 0;
      const valorAnterior = this.unidadesPorAnioEscenario[`${anioAnterior}-${escenario}`] || 0;
      const presupuesto = this.pptoUnidadesPorAnioEscenario[`${anioActual}-${escenario}`] || 0;
      const crecimiento = valorAnterior > 0 ? ((valorActual - valorAnterior) / valorAnterior * 100).toFixed(2) : 'N/A';
      const participacion = (valorActual * 100) / this.totalUnidadesRango;
      const cumplimiento = (valorActual * 100) / presupuesto;
      const pendiente = presupuesto - valorActual;

      this.resumenUnidades.push({ escenario, valorActual, valorAnterior, crecimiento, participacion, presupuesto, cumplimiento, pendiente });

      // Calculamos los totales
      this.totalUnidadesValorActual += valorActual;
      this.totalUnidadesPresupuesto += presupuesto;
      this.totalUnidadesValorAnterior += valorAnterior;
      this.totalUnidadesCumplimiento += cumplimiento;
      this.totalUnidadesCrecimiento += parseFloat(crecimiento);
      this.totalUnidadesPendiente += pendiente;
      this.totalUnidadesParticipacion += participacion;
    });

    // Ajustamos los totales de porcentaje
    this.totalUnidadesCumplimiento = this.totalUnidadesCumplimiento / this.resumenUnidades.length;
    this.totalUnidadesCrecimiento = this.totalUnidadesCrecimiento / this.resumenUnidades.length;
    this.totalUnidadesParticipacion = (this.totalUnidadesValorActual*100) / this.totalUnidadesRango;
  

  }
  limpiarFiltAgen(infotienda: any = this.inforTiendas.infoTienda) {

    let arregloAgencia = [];

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


      agencia.forEach(a => {
        if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
          arregloAgencia.push(a);
        }
      });

      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Gerente == this.coordinador);
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Gerente == this.coordinador);
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.agenciaTienda = null;
        this.agencias = [...new Set(arregloAgencia)];
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


        agencia.forEach(a => {
          if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
            arregloAgencia.push(a);
          }
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Responsable == this.coordinador);
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Responsable == this.coordinador);
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });

        agencia.map((a) => {
          if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)
            arregloAgencia.push(a);
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }


  limpiarFiltCiu(infotienda: any = this.inforTiendas.infoTienda) {

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });
      var arregloAgencia = [];
      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);

      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      }
    }
  }
  filterTiend(event) {
    if (this.codigoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.codigoTienda.filter(d => p.codigo == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterAge(event) {
    // this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
    // console.log("evento:" + event);
    if (this.agenciaTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Agencia");
  }

  filterResp() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Responsable.toString().toLowerCase() == this.coordinador.toLowerCase();
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterGer() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Gerente == this.coordinador;
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }
  filterPV(event) {
    // console.log("evento:" + event);
    if (this.ciudad != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.ciudad.filter(d => p.CIUDAD == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.CIUDAD != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("ciudad");
  }
  limpiarFiltFor(infotienda: any = this.inforTiendas.infoTienda) {

    var arregloAgencia = [];
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });

      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.formatos = [...new Set(infotienda.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }

    }
  }
  filterFormat(event) {

    // console.log("evento:" + event);
    if (this.formatoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.formatoTienda.filter(d => p.Formato == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Formato != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Formato");

  }
  seleccionarAyer() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarHoy() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getToday()
    this.toDate = this.calendar.getToday()
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarMesActual() {
    var ano: any
    var mes: any
    this.vista = 'Ac';
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    this.diamess.mes.map(x => {
      if (x.Mes == mesActual.toString().padStart(2, "0")) {
        this.fechainicioStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaInicio;
        this.fechafinStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaFin;
        ano = (this.fromDate.year).toString()
        mes = (mesActual).toString().padStart(2, "0")
        this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
        this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }
      }
    })
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  /**
 * Is Hovered
 *
 * @param date
 */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /**
   * Is Inside
   *
   * @param date
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  onDateSelection(date: NgbDate, datepicker, modalBasic) {
    this.modalOpen(modalBasic)
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  modalOpen(modalXS) {
    if (this.mostrado == false) {
      this.mostrado = true
      this.modalService.open(modalXS, {
        centered: true,
        size: 'xs'
      });
    }

  }
  modalOpen1(modalXS,escenario) {
    this.referenciasMostrar=this.referenciasPorEscenario[escenario]
      this.modalService.open(modalXS, {
        centered: true,
        size: 'xs'
      });
    

  }

}
