<div class="content-wrapper container-xxl p-0">

    <div class="content-body">
        <!-- content-header component -->

        <app-content-header [contentHeader]="contentHeader">

        </app-content-header>
        <div class="card-body">

            <!-- Range selection Date Picker -->
            <div class="card">
                <div class="row">
                    <div class="col-12">
                        <span style="font-weight: bolder;">Fechas</span>
                        <div class="row" style="margin-left:1rem;">
                            <form class="form-inline">
                                <div class="form-group">
                                    <div class="input-group w-0 ">
                                        <input name="datepicker" class="form-control" ngbDatepicker
                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                            [showWeekNumbers]="false"
                                            (dateSelect)="onDateSelection($event,datepicker,modalBasic)"
                                            [displayMonths]="1" [dayTemplate]="t" outsideDays="visible"
                                            [startDate]="fromDate!" tabindex="-1" />
                                        <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                            let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused"
                                                [class.hidden]="date.month !== currentMonth"
                                                [class.text-muted]="disabled" [class.range]="isRange(date)"
                                                [class.faded]="isHovered(date) || isInside(date)"
                                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                                {{ date.day }}


                                            </span>

                                        </ng-template>

                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <input style="display: none;" #dpFromDate class="form-control"
                                            placeholder="yyyy-mm-dd" name="dpFromDate"
                                            [value]="formatter.format(fromDate)"
                                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                                        <div class="input-group fecha">
                                            <input class="fecha" type="text" [value]="desdehasta" name="" id=""
                                                disabled>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary feather icon-calendar"
                                                (click)="datepicker.toggle()" type="button" rippleEffect></button>

                                        </div>
                                    </div>
                                </div>
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1"><i data-feather='arrow-left'></i>
                                        </h4>

                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>

                                        <p>
                                            Seleccione la fecha de inicio y la fecha fin en el mismo
                                            calendario
                                        </p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary"
                                            (click)="modal.close('Accept click')" rippleEffect>
                                            Aceptar
                                        </button>
                                    </div>
                                </ng-template>
                                <div class="form-group ml-sm-2" style="display: none;">
                                    <div class="input-group">
                                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                            [value]="formatter.format(toDate)" disabled />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary feather "
                                                (click)="datepicker.toggle()" type="button" rippleEffect
                                                disabled></button>
                                        </div>
                                    </div>
                                </div>


                            </form>



                            <div class="row">
                                <div class="columnbut">
                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                        (click)="seleccionarAnterior()" rippleEffect>
                                        Seleccionar Mes Anterior
                                    </button>
                                </div>
                                <div class="columnbut">
                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                        (click)="seleccionarMesActual()" rippleEffect>
                                        Seleccionar Mes Actual
                                    </button>
                                </div>
                                <div class="columnbut">
                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                        (click)="seleccionarHoy()" rippleEffect>
                                        Seleccionar Hoy</button>
                                </div>

                                <div class="columnbut">

                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                        (click)="seleccionarAyer()" rippleEffect>
                                        Seleccionar Ayer</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="display: inline-flex;">

                        <div class="column" style="width:20% ">
                            <div class="row">
                                <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Agencia</span>
                                <div *ngIf="mostrarfiltros" class="columy"
                                    style="padding-left: unset;padding-right: unset;">


                                    <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)"
                                        [disabled]="deshabilitado" [items]="agencias" [multiple]="true"
                                        [closeOnSelect]="false" [searchable]="true" bindLabel="nombreAgencia"
                                        placeholder="Seleccione Agencias" [(ngModel)]="agenciaTienda">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label" [value]="item.codigoAgencia">
                                                {{item.nombreAgencia}}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                    </ng-select>

                                </div>
                                <div *ngIf="mostrarfiltros" class="columx"
                                    style="padding-left: unset;padding-right: unset;">
                                    <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                                            class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                                            title="limpiar filtro">X</button></span>
                                </div>
                            </div>
                        </div>
                        <div class="column" style="width:20% ">
                            <div class="row">
                                <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Formato</span>
                                <div *ngIf="mostrarfiltros" class="columy"
                                    style="padding-left: unset;padding-right: unset;">
                                    <!-- <ng-select placeholder="Formato" style="border: 1px;border-radius: 5px;"
                                        [(ngModel)]="formatoTienda" (ngModelChange)="filterFormat($event)">
                                        <ng-option selected value="null">Formato</ng-option>
                                        <ng-option *ngFor="let item of formatos" [value]="item">{{item}}
                                        </ng-option>


                                    </ng-select> -->
                                    <ng-select [clearSearchOnAdd]="true" [clearable]="false"
                                        (blur)="filterFormat($event)" [items]="formatos" [multiple]="true"
                                        [closeOnSelect]="false" [searchable]="true" bindLabel="item"
                                        placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label" [value]="item"> {{ item }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                    </ng-select>

                                </div>
                                <div *ngIf="mostrarfiltros" class="columx"
                                    style="padding-left: unset;padding-right: unset;">
                                    <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                                        (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                                </div>
                            </div>
                        </div>
                        <div class="column" style="width:20% ">
                            <div class="row">
                                <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Ciudad</span>
                                <div *ngIf="mostrarfiltros" class="columy"
                                    style="padding-left: unset;padding-right: unset;">
                                    <!-- <ng-select placeholder="Ciudad" style="border: 1px;border-radius: 5px;" [(ngModel)]="ciudad"
          (ngModelChange)="filterPV($event)">
          <option value="null" selected>Ciudad</option>
          <ng-option *ngFor="let item of ciudades" [value]="item">{{item}}
          </ng-option>


        </ng-select> -->
                                    <ng-select [clearable]="false" (blur)="filterPV($event)" [items]="ciudades"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                                        [clearSearchOnAdd]="true" bindLabel="item" placeholder="Seleccione ciudad"
                                        [(ngModel)]="ciudad">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label" [value]="item"> {{ item }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                    </ng-select>

                                </div>
                                <div *ngIf="mostrarfiltros" class="columx"
                                    style="padding-left: unset;padding-right: unset;">
                                    <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                                        (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                                </div>
                            </div>
                        </div>
                        <div class="column" style="width:40% ">
                            <div class="row">

                                <div *ngIf="mostrarfiltros" class="columy"
                                    style="padding-left: unset;padding-right: unset;">
                                    <span style="font-weight: bolder;">Punto Venta</span>

                                    <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="false"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre"
                                        placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                    </ng-select>


                                </div>
                                <div *ngIf="mostrarfiltros" class="columx"
                                    style="padding-left: unset;padding-right: unset;">
                                    <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <button [disabled]="loading" (click)="buscar()" class="btn btn-relief-primary" tabindex="4"
                    rippleEffect>
                    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
                </button>
            </div>

        </div>
        <div class="card">
            <div class="card-header">
                <h4> <b>Ventas</b> </h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover-animation">
                        <thead>
                            <tr>
                                <th>Ventas</th>
                                <th>Valor Actual</th>
                                <th>Presupuesto</th>
                                <th>Anterior</th>
                                <th>Cumplimiento</th>
                                <th>Crecimiento</th>
                                <th>Pendiente (Ppto-Actual)</th>
                                <th>Participacion Venta Total</th>
                                <th>Referencias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let venta of resumenVentas">
                                <th>{{ venta.escenario }}</th>
                                <td>
                                    {{venta?.valorActual | currency : '$' : 'symbol' :'1.0-0'}}
                                </td>
                                <td>{{venta?.presupuesto | currency : '$' : 'symbol' :'1.0-0'}}</td>
                                <td>
                                    {{venta?.valorAnterior | currency : '$' : 'symbol' :'1.0-0'}}
                                </td>
                                <td>{{venta?.cumplimiento| number:'1.0-0'}}%</td>
                                <td>
                                    {{venta?.crecimiento| number:'1.0-0'}}%
                                </td>
                                <td>{{venta?.pendiente | currency : '$' : 'symbol' :'1.0-0'}}</td>
                                <td>{{(venta?.participacion) | number:'1.0-0'}}%</td>
                                <td><button class="btn btn-primary"
                                        (click)="modalOpen1(referenceModal,venta?.escenario)">Ver Referencias</button>
                                    <!-- <span *ngFor="let referencia of referenciasPorEscenario[venta?.escenario]">
                            <label>{{referencia}},</label>
                        </span> -->
                                </td>

                            </tr>
                            <tr *ngIf="resumenVentas.length > 0">
                                <th>Total</th>
                                <td>{{ totalValorActual | currency: '$' : 'symbol' : '1.0-0' }}</td>
                                <td>{{ totalPresupuesto | currency: '$' : 'symbol' : '1.0-0' }}</td>
                                <td>{{ totalValorAnterior | currency: '$' : 'symbol' : '1.0-0' }}</td>
                                <td>{{ totalCumplimiento | number: '1.0-0' }}%</td>
                                <td>{{ totalCrecimiento | number: '1.0-0' }}%</td>
                                <td>{{ totalPendiente | currency: '$' : 'symbol' : '1.0-0' }}</td>
                                <td>{{ totalParticipacion | number: '1.0-0' }}%</td>
                                <td></td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div class="card">
            <div class="card-header">
                <h4> <b>Unidades</b> </h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover-animation">
                        <thead>
                            <tr>
                                <th>Unidades</th>
                                <th>Valor Actual Unidades</th>
                                <th>Presupuesto Unidades</th>
                                <th>Anterior Unidades</th>
                                <th>Cumplimiento Unidades</th>
                                <th>Crecimiento Unidades</th>
                                <th>Pendiente (Ppto-Actual) Unidades</th>
                                <th>Participacion Venta Total Unidades</th>
                                <th>Numero de Referencias Actuales</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let unidad of resumenUnidades">
                                <th>{{ unidad?.escenario }}</th>
                                <td>
                                    {{unidad?.valorActual | number:'1.0-0'}}
                                </td>
                                <td>{{unidad?.presupuesto | number:'1.0-0' }}</td>
                                <td>
                                    {{unidad?.valorAnterior| number:'1.0-0'}}
                                </td>
                                <td>{{unidad?.cumplimiento | number:'1.0-0'}} %</td>
                                <td>
                                    {{unidad?.crecimiento | number:'1.0-0'}}%
                                </td>
                                <td>{{unidad?.pendiente | number}}</td>
                                <td>{{(unidad?.participacion) | number:'1.0-0'}}%</td>
                                <td><button class="btn btn-primary"
                                        (click)="modalOpen1(referenceModal,unidad?.escenario)">Ver Referencias</button>
                                </td>
                            </tr>
                            <tr *ngIf="resumenVentas.length > 0">
                                <th>Total</th>
                                <td>{{ totalUnidadesValorActual | number: '1.0-0' }}</td>
                                <td>{{ totalUnidadesPresupuesto | number: '1.0-0' }}</td>
                                <td>{{ totalUnidadesValorAnterior | number: '1.0-0' }}</td>
                                <td>{{ totalUnidadesCumplimiento | number: '1.0-0' }}%</td>
                                <td>{{ totalUnidadesCrecimiento | number: '1.0-0' }}%</td>
                                <td>{{ totalUnidadesPendiente | number: '1.0-0' }}</td>
                                <td>{{ totalUnidadesParticipacion | number: '1.0-0' }}%</td>
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #referenceModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"><i data-feather='arrow-left'></i></h4>

    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let reference of referenciasMostrar; let i = index">
                <span class="badge bg-primary">{{ i + 1 }}</span>
                {{ reference }}
            </li>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Aceptar
        </button>
    </div>
</ng-template>