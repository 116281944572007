export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Metas',
      SAMPLE: 'Detalle diario',
      HORAINGRESO: 'Apertura-Cierre',
      CUMULADOPT:'Detalle Acum',
      SUBIRPPTO:'subir Presupuesto',
      HEATMAP:'Informe por Hora',
      SUBIRFACTURA:'Subir-Facturacion',
      CONSULTACOMPROBANTE:'Ver-Comprobante',
      CONSULTACONCESIONES:'Concesiones',
      OCUPACIONASESORAS:'Ocupacion-Tiendas',
      PANAMA:'Panama',
      DATOSREPOSPEDIDO:'Subir-Datos-Repos-Pedidos',
      CONSULTARREPOSPEDIDOS:'Consultar-Repos-Pedidos',
      PERU:'Peru',
      REFERENCIAS:'Voluminizacion',
      CONFIGVOLUMINIZACION:'ConfigVoluminizacion',
      RECALCULOTRANSACCIONES:'Recalculo-Transacciones'

    }
  }
}
