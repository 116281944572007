import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { menu2 } from 'app/menu/menu2';
import { menu3 } from 'app/menu/menu3';
import { menu4 } from 'app/menu/menu4';
import { menu } from 'app/menu/menu';
import { menuConcesiones } from 'app/menu/menuConcesiones';
import { menuPanama } from 'app/menu/menuPanama';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { menuPeru } from 'app/menu/menuPeru';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  rol: string;
  responsable: string;


  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.rol = sessionStorage.getItem('rol')
    this.responsable = sessionStorage.getItem('responsable')
    if(this.responsable=="Juan Gabriel Largo Ramirez"){
      this.menu = menuPeru;
      this._coreMenuService.unregister('main')
      // Register the menu to the menu service
      this._coreMenuService.register('main', this.menu);

      // Set the main menu as our current menu
      this._coreMenuService.setCurrentMenu('main');
    }else if (this.responsable == "TMed048 Tienda Leonisa Santa Fe Caja 2") {
      this.menu = menu4;
      this._coreMenuService.unregister('main')
      // Register the menu to the menu service
      this._coreMenuService.register('main', this.menu);

      // Set the main menu as our current menu
      this._coreMenuService.setCurrentMenu('main');
    } else if (this.rol == "" || this.rol == "null") {
      if(this.responsable=="Lineth Yanubis Sanchez Gaitan"){
        this.menu = menuPanama;
        this._coreMenuService.unregister('main')
        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);

        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
      }else{
        this.menu = menu2;
        this._coreMenuService.unregister('main')
        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);
  
        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
      }
      
    } else if(this.responsable=="Ricardo Efrain Guerrero Burbano"){
        this.menu = menuConcesiones;
        this._coreMenuService.unregister('main')
        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);
  
        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
    }else{

      if (this.responsable == "Jose Mario Tamayo Lopera" || this.responsable == "Jose Fernando Tabares Muñoz" || this.responsable == "Jesus Maria Vasquez Sarrazola" || this.responsable == "Mariana  Zapata Jaramillo" || this.responsable == "Juan Carlos Maya Arbelaez" || this.responsable=="Carlos Ignacio Urrea" || this.responsable=="Andres Felipe Sanchez Blandon" || this.responsable=="Julian Alberto Navarro Garcia") {
        this.menu = menu3;
        this._coreMenuService.unregister('main')
        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);

        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
      } else {
      {
          this.menu = menu;
          this._coreMenuService.unregister('main')
          // Register the menu to the menu service
          this._coreMenuService.register('main', this.menu);
  
          // Set the main menu as our current menu
          this._coreMenuService.setCurrentMenu('main');
        }
        
      }
    }
    // Set the menu either from the input or from the service
    this.menu = this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
}
