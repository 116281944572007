import { CoreMenu } from '@core/types'

export const menu2: 
CoreMenu[] = [
  
  {
    id: 'home',
    title: 'Metas',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  }, {
    id: 'subirFactura',
    title: 'Subir-Consignacion GPT 3.5 AI',
    translate: 'MENU.SUBIRFACTURA',
    type: 'item',
    icon: 'layers',
    url: 'subirFactura'
  }
  // ,
  // {
  //   id: 'subirReposPedido',
  //   title: 'Subir Repos Pedido',
  //   translate: 'MENU.DATOSREPOSPEDIDO',
  //   type: 'item',
  //   icon: 'layers',
  //   url: 'subirDataReposPedido'
  // },
  // {
  //   id: 'consultarReposPedidos',
  //   title: 'Consultar Repos',
  //   translate: 'MENU.CONSULTARREPOSPEDIDOS',
  //   type: 'item',
  //   icon: 'layers',
  //   url: 'consultarReposPedidos'
  // }
]
