<div class="content-wrapper container-xxl p-0">

    <div class="content-body">
        <!-- content-header component -->

        <app-content-header [contentHeader]="contentHeader">

        </app-content-header>
        <section class="range-selection-DP">

            <div class="row">
                <div class="col-12">
                    <div class="card">

                        <div class="card-body">

                            <!-- Range selection Date Picker -->
                            <div class="card">
                                <div class="row">
                                    <div class="col-12">
                                        <span style="font-weight: bolder;">Fechas</span>
                                        <div class="row" style="margin-left:2rem;">
                                            <form class="form-inline">
                                                <div class="form-group">
                                                    <div class="input-group w-0 ">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            [showWeekNumbers]="false"
                                                            (dateSelect)="onDateSelection($event,datepicker,modalBasic)"
                                                            [displayMonths]="1" [dayTemplate]="t" outsideDays="visible"
                                                            [startDate]="fromDate!" tabindex="-1" />
                                                        <ng-template #t let-date let-currentMonth="currentMonth"
                                                            let-selected="selected" let-focused="focused">
                                                            <span class="custom-day" [class.focused]="focused"
                                                                [class.hidden]="date.month !== currentMonth"
                                                                [class.text-muted]="disabled"
                                                                [class.range]="isRange(date)"
                                                                [class.faded]="isHovered(date) || isInside(date)"
                                                                (mouseenter)="hoveredDate = date"
                                                                (mouseleave)="hoveredDate = null">
                                                                {{ date.day }}


                                                            </span>

                                                        </ng-template>

                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input style="display: none;" #dpFromDate class="form-control"
                                                            placeholder="yyyy-mm-dd" name="dpFromDate"
                                                            [value]="formatter.format(fromDate)"
                                                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                                            disabled />
                                                        <div class="input-group fecha">
                                                            <input class="fecha" type="text" [value]="desdehasta"
                                                                name="" id="" disabled>
                                                        </div>
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-template #modalBasic let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="myModalLabel1"><i
                                                                data-feather='arrow-left'></i></h4>

                                                    </div>
                                                    <div class="modal-body" tabindex="0" ngbAutofocus>

                                                        <p>
                                                            Seleccione la fecha de inicio y la fecha fin en el mismo
                                                            calendario
                                                        </p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-primary"
                                                            (click)="modal.close('Accept click')" rippleEffect>
                                                            Aceptar
                                                        </button>
                                                    </div>
                                                </ng-template>
                                                <div class="form-group ml-sm-2" style="display: none;">
                                                    <div class="input-group">
                                                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd"
                                                            name="dpToDate" [value]="formatter.format(toDate)"
                                                            disabled />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary feather "
                                                                (click)="datepicker.toggle()" type="button" rippleEffect
                                                                disabled></button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>



                                            <div class="row">
                                                <div class="columnbut">
                                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                        (click)="seleccionarAnterior()" rippleEffect>
                                                        Seleccionar Mes Anterior
                                                    </button>
                                                </div>
                                                <div class="columnbut">
                                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                        (click)="seleccionarMesActual()" rippleEffect>
                                                        Seleccionar Mes Actual
                                                    </button>
                                                </div>
                                                <div class="columnbut">
                                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                        (click)="seleccionarHoy()" rippleEffect>
                                                        Seleccionar Hoy</button>
                                                </div>

                                                <div class="columnbut">

                                                    <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                        (click)="seleccionarAyer()" rippleEffect>
                                                        Seleccionar Ayer</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="none ">
                                <button type="button" class="btn btn-outline-primary" rippleEffect
                                    (click)="mostrarFiltross()">
                                    <span *ngIf="mostrarfiltrosi"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-chevron-down">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg></span>
                                    <span *ngIf="mostrarfiltros"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-chevron-up">
                                            <polyline points="18 15 12 9 6 15"></polyline>
                                        </svg></span>Filtros</button>
                            </div>
                        </div>

                        <div class="row" style="display: inline-flex;">

                            <div class="column" style="width:20% ">
                                <div class="row">
                                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Agencia</span>
                                    <div *ngIf="mostrarfiltros" class="columy"
                                        style="padding-left: unset;padding-right: unset;">


                                        <ng-select [clearSearchOnAdd]="true" [clearable]="false"
                                            (blur)="filterAge($event)" [disabled]="deshabilitado" [items]="agencias"
                                            [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                                            bindLabel="nombreAgencia" placeholder="Seleccione Agencias"
                                            [(ngModel)]="agenciaTienda">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label" [value]="item.codigoAgencia">
                                                    {{item.nombreAgencia}}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                        </ng-select>

                                    </div>
                                    <div *ngIf="mostrarfiltros" class="columx"
                                        style="padding-left: unset;padding-right: unset;">
                                        <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                                                class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                                                title="limpiar filtro">X</button></span>
                                    </div>
                                </div>
                            </div>
                            <div class="column" style="width:20% ">
                                <div class="row">
                                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Formato</span>
                                    <div *ngIf="mostrarfiltros" class="columy"
                                        style="padding-left: unset;padding-right: unset;">
                                        <!-- <ng-select placeholder="Formato" style="border: 1px;border-radius: 5px;"
      [(ngModel)]="formatoTienda" (ngModelChange)="filterFormat($event)">
      <ng-option selected value="null">Formato</ng-option>
      <ng-option *ngFor="let item of formatos" [value]="item">{{item}}
      </ng-option>


    </ng-select> -->
                                        <ng-select [clearSearchOnAdd]="true" [clearable]="false"
                                            (blur)="filterFormat($event)" [items]="formatos" [multiple]="true"
                                            [closeOnSelect]="false" [searchable]="true" bindLabel="item"
                                            placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label" [value]="item"> {{ item }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                        </ng-select>

                                    </div>
                                    <div *ngIf="mostrarfiltros" class="columx"
                                        style="padding-left: unset;padding-right: unset;">
                                        <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger"
                                            rippleEffect (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                                    </div>
                                </div>
                            </div>
                            <div class="column" style="width:20% ">
                                <div class="row">
                                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Ciudad</span>
                                    <div *ngIf="mostrarfiltros" class="columy"
                                        style="padding-left: unset;padding-right: unset;">
                                        <!-- <ng-select placeholder="Ciudad" style="border: 1px;border-radius: 5px;" [(ngModel)]="ciudad"
      (ngModelChange)="filterPV($event)">
      <option value="null" selected>Ciudad</option>
      <ng-option *ngFor="let item of ciudades" [value]="item">{{item}}
      </ng-option>


    </ng-select> -->
                                        <ng-select [clearable]="false" (blur)="filterPV($event)" [items]="ciudades"
                                            [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                                            [clearSearchOnAdd]="true" bindLabel="item" placeholder="Seleccione ciudad"
                                            [(ngModel)]="ciudad">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label" [value]="item"> {{ item }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                        </ng-select>

                                    </div>
                                    <div *ngIf="mostrarfiltros" class="columx"
                                        style="padding-left: unset;padding-right: unset;">
                                        <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger"
                                            rippleEffect (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                                    </div>
                                </div>
                            </div>
                            <div class="column" style="width:40% ">
                                <div class="row">

                                    <div *ngIf="mostrarfiltros" class="columy"
                                        style="padding-left: unset;padding-right: unset;">
                                        <span style="font-weight: bolder;">Punto Venta</span>

                                        <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="false"
                                            [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                                            bindLabel="Nombre" placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre
                                                    }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                        </ng-select>


                                    </div>
                                    <div *ngIf="mostrarfiltros" class="columx"
                                        style="padding-left: unset;padding-right: unset;">
                                        <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger"
                                            rippleEffect (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                            (click)="buscarDatos()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
                        </button>

                    </div>

                </div>

            </div>



        </section>
        <!-- <div class="card">
            <div class="card-header">

            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Real</th>
                        <th>Pendiente</th>
                        <th>Meta</th>
                        <th>%</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngFor="let item of diar">{{item.totalTransRecalculado}}</td>
                            <td>{{pendiente}}</td>
                            <td *ngFor="let item of diar">{{item.prespuestoTransaccionesFinalReclaculado}}</td>
                            <td *ngIf="cumplimiento!=0">{{cumplimiento}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div id="chart" style="max-width: 95%; max-height: 100%; width: 100%; height: 100%; overflow: hidden;">
                <apx-chart 
                  [series]="chartOptions.series" 
                  [chart]="chartOptions.chart" 
                  [xaxis]="chartOptions.xaxis" 
                  [plotOptions]="chartOptions.plotOptions" 
                  [fill]="chartOptions.fill"
                  [colors]="chartOptions.colors"
                  [title]="chartOptions.title"
                  [legend]="chartOptions.legend"
                  >
                </apx-chart>
            </div>
        </div>
        
    </div>
    
</div>
