import {
    Injectable
} from "@angular/core"

@Injectable({

    providedIn: "root"
})
export class InfoTiendaData {

    infoConcesion = [
        {

            "CodigoWinPOS": "1791",
            "NOMBRE": "ALMACEN ESTRELLA 20 DE JULIO",
            "Responsable": "Ricardo Efrain Guerrero Burbano"
        },
        {

            "CodigoWinPOS": "1790",
            "NOMBRE": "ALMACEN ESTRELLA CENTRO",
            "Responsable": "Ricardo Efrain Guerrero Burbano"
        }
    ]

    infoTienda = [];

    infoPanama=[
        {

            "CodigoWinPOS": "0911",
            "NOMBRE": "TIENDA OBARRIO"
        },{
            "CodigoWinPOS": "0921",
            "NOMBRE": "TIENDA MULTIPLAZA"
        },{
            "CodigoWinPOS": "0931",
            "NOMBRE": "TIENDA ALTAPLAZA"
        },{
            "CodigoWinPOS": "0941",
            "NOMBRE": "TIENDA ALBROOK"
        }

    ]

    infoPeru=[
        {

            "CodigoWinPOS": "0034",
            "NOMBRE": "TIENDA AREQUIPA"
        },{
            "CodigoWinPOS": "0139",
            "NOMBRE": "Plaza lima Norte"
        },{
            "CodigoWinPOS": "0138",
            "NOMBRE": "Tienda Mall del Sur"
        },{
            "CodigoWinPOS": "0137",
            "NOMBRE": "TIENDA LARCOMAR"
        },{
            "CodigoWinPOS": "0032",
            "NOMBRE": "TIENDA SAN MIGUEL"
        },{
            "CodigoWinPOS": "0037",
            "NOMBRE": "TIENDA MEGAPLAZA"
        },{
            "CodigoWinPOS": "0142",
            "NOMBRE": "TIENDA FAUCETT"
        },{
            "CodigoWinPOS": "0031",
            "NOMBRE": "JOCKEY PLAZA"
        },{
            "CodigoWinPOS": "0199",
            "NOMBRE": "TIENDA SHOWROOM DERBY"
        },{
            "CodigoWinPOS": "0131",
            "NOMBRE": "TIENDA LA RAMBLA"
        },{
            "CodigoWinPOS": "0135",
            "NOMBRE": "AVENTURA  PLAZA  TRUJILLO"
        }

    ]
    infoTiendaAll = [
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1489",
            "CodigoWinPOS": "141",

            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET UNICO No.1",
            "DIRECCION": "Cra. 3 Calle 52 - 82 Loc. 16A Centro Comercial Unico (Calle 53 # 3-29 Etapa1)"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1491",
            "CodigoWinPOS": "329",

            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA COSMOCENTRO",
            "DIRECCION": "Calle 5 No.50 - 80 Centro Comercial La 14 Loc 26B"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1499",
            "CodigoWinPOS": "589",

            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA UNICENTRO CALI",
            "DIRECCION": "Cra. 100 No.Av 5 - 169 Loc 239 Centro Comercial Unicentro Cali"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1501",
            "CodigoWinPOS": "598",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "ZEBRA CENTRO CALI",
            "DIRECCION": "Calle 13 No.8 - 57 Loc 50 Centro Comercial Calles de Oro"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1502",
            "CodigoWinPOS": "617",
            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA JARDIN PLAZA",
            "DIRECCION": "Unidad Comercial No.91 del Centro Comercial Jardín Plaza ubicado entre las carreras 100 y 98 y Avenida Simón Bolívar Calle 16 de Santiago de Cali"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1241",
            "CodigoWinPOS": "625",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA MULTIPLAZA  ",
            "DIRECCION": "Calle 13 con Avenida Boyacá (Calle 19 a #72 -57)"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1823",
            "CodigoWinPOS": "678",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "6",
            "Formato": "DUTY FREE",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "DUTY FREE RIONEGRO",
            "DIRECCION": "Aeropuerto José Maria Córdova de Rionegro Local E28, ubicado en el área de DUTTY FREE. (Antes Loc 2242 B)"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1922",
            "CodigoWinPOS": "509",
            "Responsable": "Freddy Rafael Escorcia Diago",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Soledad",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET NUESTRO ATLANTICO",
            "DIRECCION": ""
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1824",
            "CodigoWinPOS": "679",
            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "6",
            "Formato": "DUTY FREE",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "DUTY FREE PALMIRA",
            "DIRECCION": "Aeropuerto Alfonso Bonilla Aragón de Palmira - Valle . Local Punto #4 Ubicación 020 Centro Comercial"
        },
        {
            "idAsesorComercialTPA": "131",
            "idTiendaTPA": "",
            "CodigoWinPOS": "1846",
            "Responsable": "Ricardo Efrain Guerrero Burbano",
            "IdFormato": "2",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Ipiales",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "DISSEN S.A.S.TIENDA LUMAR IPIALES",
            "DIRECCION": ""
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "",
            "CodigoWinPOS": "512",
            "Responsable": "Marian Camila Sanchez Benavides",
            "IdFormato": "2",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Montería",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "Tienda Leonisa Nuestro Montería",
            "DIRECCION": ""
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1289",
            "CodigoWinPOS": "1004",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA SANTA FE DE BOGOTA",
            "DIRECCION": "Calle 185 No.45 - 03 - Costado Occidental Autopista Norte Locales 1-97 y 1-98"
        },
        {
            "idAsesorComercialTPA": "119",
            "idTiendaTPA": "1290",
            "CodigoWinPOS": "1005",
            "Responsable": "Maria Eva Llanos Fernandez",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Neiva",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA NEIVA",
            "DIRECCION": "CALLE 64 Nro. 1D-140 Centro Comercial del Huila LC 31"
        },
        {
            "idAsesorComercialTPA": "125",
            "idTiendaTPA": "1291",
            "CodigoWinPOS": "1006",
            "Responsable": "Federico Lopez Velasquez",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Villavicencio",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA PRIMAVERA URBANA",
            "DIRECCION": "Calle 15 / Av. Circunvalar Lote 1 LOCAL 137"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1768",
            "CodigoWinPOS": "1008",
            "Responsable": "Gloria Stefanie Martinez Plata",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "CARTAGENA DE INDIAS",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA MALL PLAZA EL CASTILLO",
            "DIRECCION": "Cra 13 31-45 Chambacú"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1769",
            "CodigoWinPOS": "1009",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA VIVA BARRANQUILLA",
            "DIRECCION": "Av. Alberto Assa #87-50"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1293",
            "CodigoWinPOS": "1010",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA TITAN",
            "DIRECCION": "Calle 80 con Avenida Boyacá local 230"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1294",
            "CodigoWinPOS": "1015",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA PARQUE LA COLINA",
            "DIRECCION": "Cra 58D Nro 146-51 Local 252"
        },
        {
            "idAsesorComercialTPA": "109",
            "idTiendaTPA": "1770",
            "CodigoWinPOS": "1016",
            "Responsable": "Freddy Rafael Escorcia Diago",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Valledupar",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA GUATAPURI VALLEDUPAR",
            "DIRECCION": "Av. Hurtado Diag. 10 No. 6N - 15 Locales 1-012 y 1013"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1295",
            "CodigoWinPOS": "1017",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA PLAZA CENTRAL",
            "DIRECCION": "Carrera 65 # 11 – 50 (Entre Avenida de las Américas y Calle 13) local 1-41"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1772",
            "CodigoWinPOS": "1018",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA PORTAL DEL PRADO",
            "DIRECCION": "Calle 53 N 46-192 Local 22"
        },
        {
            "idAsesorComercialTPA": "111",
            "idTiendaTPA": "1296",
            "CodigoWinPOS": "1021",
            "Responsable": "Josefina  Tobar Reyes",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Tunja",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA VIVA TUNJA",
            "DIRECCION": "Av. Universitaria 50 21 lote 1B local 131"
        },
        {
            "idAsesorComercialTPA": "",
            "idTiendaTPA": "",
            "CodigoWinPOS": "1028",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA PLAZA NQS BOGOTÁ",
            "DIRECCION": "av Cra 86 #55A-75 local 122 Engativá"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1898",
            "CodigoWinPOS": "1042",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA NUESTRO BOGOTÁ",
            "DIRECCION": "av Cra 86 #55A-75 local 122 Engativá"
        },
        {
            "idAsesorComercialTPA": "109",
            "idTiendaTPA": "1775",
            "CodigoWinPOS": "1061",
            "Responsable": "Freddy Rafael Escorcia Diago",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Santa Marta",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA BUENAVISTA SANTA MARTA",
            "DIRECCION": "Av. Libertador Frente a la Quinta de San Pedro local 65"
        },
        {
            "idAsesorComercialTPA": "109",
            "idTiendaTPA": "1843",
            "CodigoWinPOS": "1069",
            "Responsable": "Freddy Rafael Escorcia Diago",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Valledupar",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "LUMAR BY LEONISA VALLEDUPAR",
            "DIRECCION": "Calle 16B #7-74"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1517",
            "CodigoWinPOS": "1142",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET UNICO No.2",
            "DIRECCION": "Calle 52 No.3 - 80 Centro Comercial Unico 2 Loc 318"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1518",
            "CodigoWinPOS": "1143",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET LEONISA UNICO No. 3",
            "DIRECCION": "Calle 52 No.3 - 80 Loc 488 Centro Comercial Unico Tercera Etapa III"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1519",
            "CodigoWinPOS": "1144",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "ZEBRA CENTRO CALI #2",
            "DIRECCION": "Carrera 8va No. 13 – 34 y 38 – 42, primer piso"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1520",
            "CodigoWinPOS": "1149",
            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Popayán",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA CAMPANARIO POPAYAN",
            "DIRECCION": "Cra 9 No. 24 AN 21 Loc 23 CAMPANARIO CENTRO COMERCIAL"
        },
        {
            "idAsesorComercialTPA": "131",
            "idTiendaTPA": "1521",
            "CodigoWinPOS": "1150",
            "Responsable": "Ricardo Efrain Guerrero Burbano",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Pasto",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET UNICO PASTO",
            "DIRECCION": "Calle 22 # 6 - 61 Local 39 Centro Comercial Único"
        },
        {
            "idAsesorComercialTPA": "131",
            "idTiendaTPA": "1572",
            "CodigoWinPOS": "1702",
            "Responsable": "Ricardo Efrain Guerrero Burbano",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Pasto",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA UNICENTRO PASTO",
            "DIRECCION": "Calle 11 No. 34 - 78 Local 230"
        },
        {
            "idAsesorComercialTPA": "110",
            "idTiendaTPA": "1825",
            "CodigoWinPOS": "1773",
            "Responsable": "Alba Lucia Montes Ayala",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Manizales",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA MALL PLAZA MANIZALES",
            "DIRECCION": "Avenida Kevin Ángel # 55D - 251 Local A1072(B)"
        },
        {
            "idAsesorComercialTPA": "110",
            "idTiendaTPA": "1924",
            "CodigoWinPOS": "1842",
            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA MALL PLAZA CALI",
            "DIRECCION": "Avenida Kevin Ángel # 55D - 251 Local A1072(B)"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1834",
            "CodigoWinPOS": "1783",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Yumbo",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET UNICO YUMBO",
            "DIRECCION": "Calle 15 No. 5 – 30 local 27"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1878",
            "CodigoWinPOS": "1788",
            "Responsable": "Maria Camila Sanchez Giraldo",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LUMAR BY LEONISA Cali CC NORTE",
            "DIRECCION": "Avenida 5 Norte # 24 N - 26 Local 7 al lado de Punto Blanco, Barrio San Vicente"
        },


        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1015",
            "CodigoWinPOS": "2216",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA LA 33",
            "DIRECCION": "Calle 37 No.48 - 34 Av La 33 Manzana Leonisa"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1016",
            "CodigoWinPOS": "2220",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA VESTIDOS DE BAÑO",
            "DIRECCION": "Calle 14 No.52A - 89 (Cerca al Aeropuerto)"
        },
        {
            "idAsesorComercialTPA": "130",
            "idTiendaTPA": "1029",
            "CodigoWinPOS": "2705",
            "Responsable": "Andrea  Ayala Aguas",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bucaramanga",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA CACIQUE BUCARAMANGA",
            "DIRECCION": "Transversal 93 N 34- 99 El Tejar"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1030",
            "CodigoWinPOS": "2708",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Sabaneta",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA MAYORCA",
            "DIRECCION": "Centro comercial Mayorca Local 1259"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1041",
            "CodigoWinPOS": "2847",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA EL TESORO 2",
            "DIRECCION": "Carrera 25A No.1A-Sur - 45 Local 1415 en el Parque Comercial el Tesoro"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1062",
            "CodigoWinPOS": "3006",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA CENTRO MERCANTIL",
            "DIRECCION": "Carrera 53 N 29A-130 Local 101 Centro Mercantil N2"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1066",
            "CodigoWinPOS": "3039",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA VIVA ENVIGADO",
            "DIRECCION": "Cra 48 34 sur - 29 local 107"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1827",
            "CodigoWinPOS": "3068",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Itagui",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "ZEBRA LEONISA BLOQUE NARANJA",
            "DIRECCION": "CALLE 85 # 48-01 LOCAL 0225 BLOQUE NARANJA"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1074",
            "CodigoWinPOS": "3081",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA ESTACION SAN ANTONIO",
            "DIRECCION": "Calle 46 # 50-59"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1880",
            "CodigoWinPOS": "3133",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "PUNTO DE VENTA ESPECIAL BAZARES",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "NULL",
            "idTiendaTPA": "1872",
            "CodigoWinPOS": "3184",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Whatsapp",
            "Regional": "08",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "LINEA WHATSAPP",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "118",
            "idTiendaTPA": "1837",
            "CodigoWinPOS": "1026",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "EVENTOS TIENDA BOGOTA",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1838",
            "CodigoWinPOS": "1027",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "EVENTOS OUTLET BOGOTA",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1839",
            "CodigoWinPOS": "1077",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "EVENTOS TIENDA BARRANQUILLA",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "2021",
            "idTiendaTPA": "1841",
            "CodigoWinPOS": "1787",
            "Responsable": "Sebelinda  Quitumbo Meza",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "EVENTOS TIENDA CALI",
            "DIRECCION": "N/A"
        },
        {
            "idAsesorComercialTPA": "NULL",
            "idTiendaTPA": "1844",
            "CodigoWinPOS": "3371",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "EVENTOS TIENDA MEDELLIN",
            "DIRECCION": "N/A"
        },

        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1885",
            "CodigoWinPOS": "3281",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA PASEO VILLA DEL RIO",
            "DIRECCION": "cra 63 # 57G-46 Sur Local 156"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1902",
            "CodigoWinPOS": "3472",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "BELLO",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA PLAZA FABRICATO",
            "DIRECCION": "Carrera 50 #38-201 CC Plaza Fabricato local 1007"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1084",
            "CodigoWinPOS": "4032",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA VENTA ESPECIAL MEDELLIN",
            "DIRECCION": "Cra 50 No.37 - 66 (Anteriormente en Calle 37 No. 37 - 27)"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1085",
            "CodigoWinPOS": "4033",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "ZEBRA TERMINAL 324",
            "DIRECCION": "Cra 64C No. 78 - 58 Loc 580 Centro Comercial Terminal Norte"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1086",
            "CodigoWinPOS": "4037",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET JUNIN",
            "DIRECCION": "Cra. 49 No.50 - 41 / 43 Loc 101 Centro Comercial Junin La Candelaria"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1087",
            "CodigoWinPOS": "4039",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "ZEBRA TERMINAL DEL SUR",
            "DIRECCION": "Cra 65 No.8B - 91 Loc 176 y 177 Centro Comercial Terminal del Sur"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1088",
            "CodigoWinPOS": "4045",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "ZEBRA AVENIDA 33",
            "DIRECCION": "Calle 37 No.48 - 19"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1116",
            "CodigoWinPOS": "5551",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA LOS MOLINOS",
            "DIRECCION": "Calle 30 No.82A - 50 Centro Comercial Molinos Loc 1310"
        },
        {
            "idAsesorComercialTPA": "110",
            "idTiendaTPA": "1676",
            "CodigoWinPOS": "5888",
            "Responsable": "Alba Lucia Montes Ayala",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Manizales",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LUMAR BY LEONISA MANIZALES",
            "DIRECCION": "Calle 19 No.22 - 19 Loc 106"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1794",
            "CodigoWinPOS": "5902",
            "Responsable": "Gloria Stefanie Martinez Plata",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "CARTAGENA DE INDIAS",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LUMAR BY LEONISA PASEO LA CASTELLANA",
            "DIRECCION": "Av. Pedro de Heredia Calle 30 No.64 - 87 Loc 14B Centro Comercial Paseo La Castellena II etapa"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1795",
            "CodigoWinPOS": "5903",
            "Responsable": "Gloria Stefanie Martinez Plata",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "CARTAGENA DE INDIAS",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET LEONISA PLAZUELA CARTAGENA",
            "DIRECCION": "Calle 31 No.71- 132 Centro Comercial Plazuelas Loc 1-19"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1796",
            "CodigoWinPOS": "5906",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET UNICO LEONISA BARRANQUILLA",
            "DIRECCION": "Cll 74 No.38D-113 Loc 96 CC Unico"
        },
        {
            "idAsesorComercialTPA": "2030",
            "idTiendaTPA": "1798",
            "CodigoWinPOS": "5912",
            "Responsable": "Marian Camila Sanchez Benavides",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Montería",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA BUENAVISTA MONTERIA",
            "DIRECCION": "CRA 6a No 68-72 local 121"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1799",
            "CodigoWinPOS": "5913",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA BUENAVISTA BARRANQUILLA",
            "DIRECCION": "Calle 98 Cra 52 - 115 local 118 Centro Comercial Buenavista Barranquilla"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1117",
            "CodigoWinPOS": "5921",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA SUCRE",
            "DIRECCION": "Cra. 47 No.52 - 126 / 130 Centro Comercial El Paso"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1118",
            "CodigoWinPOS": "5923",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Sabaneta",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "ZEBRA SABANETA",
            "DIRECCION": "Calle 51 Sur No.48 - 72 Loc. 5"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1119",
            "CodigoWinPOS": "5924",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Itagui",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA SUPER CENTRO",
            "DIRECCION": "Cra. 52D No.81 - 41 Loc 101 Centro Comercial Super Centro"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1120",
            "CodigoWinPOS": "5925",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Sabaneta",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA MAYORCA",
            "DIRECCION": "Calle 51 Sur No.48 - 57 Centro Comercial Mayorca Loc 114"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1122",
            "CodigoWinPOS": "5929",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA OVIEDO",
            "DIRECCION": "Cra 43A Calle 6 Sur - 15 Loc 123 Bloque \"B\" Centro Comercial Oviedo"
        },
        {
            "idAsesorComercialTPA": "112",
            "idTiendaTPA": "1678",
            "CodigoWinPOS": "5949",
            "Responsable": "John James Galeano Carvajal",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "DOSQUEBRADAS",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "OUTLET DOS QUEBRADAS",
            "DIRECCION": "Cra 16 con Calle 24 Centro Comercial Unico Dos Quebradas Loc 59"
        },
        {
            "idAsesorComercialTPA": "125",
            "idTiendaTPA": "1412",
            "CodigoWinPOS": "5969",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "DISTRIBUIDORA TEXTIL NOVELTY CIA. NOVELTY",
            "DIRECCION": "Cra 53 # 49-69---------AVENIDA 37B N° 42 - 155"
        },
        {
            "idAsesorComercialTPA": "1751",
            "idTiendaTPA": "1125",
            "CodigoWinPOS": "5976",
            "Responsable": "Victor Alfonso Jaramillo Escobar",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET LEONISA LA 65",
            "DIRECCION": "Cra 65 No. 14 - 70 Barrio San Pablo"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1126",
            "CodigoWinPOS": "5977",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Medellín",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA SANTAFE MEDELLIN",
            "DIRECCION": "Cra 43 A 7 Sur 130 Loc 1206 Centro Comercial Santa Fe Medellín"
        },
        {
            "idAsesorComercialTPA": "2085",
            "idTiendaTPA": "1127",
            "CodigoWinPOS": "5988",
            "Responsable": "Juana  Estrada Sierra",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Rionegro",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "TIENDA LEONISA SAN NICOLAS",
            "DIRECCION": "Calle 43 No. 54-139 Loc 2277 Piso 2 CONJUNTO INMOBILIARIO SAN NICOLAS P.H,"
        },
        {
            "idAsesorComercialTPA": "110",
            "idTiendaTPA": "1679",
            "CodigoWinPOS": "5989",
            "Responsable": "Alba Lucia Montes Ayala",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Pereira",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA ALAMEDAS PEREIRA",
            "DIRECCION": "Centro Comercial Alamedas Av Circunvalar Entre las calles 5 y 6 Loc 319B Piso 2 (PARQUE ARBOLEDA)"
        },
        {
            "idAsesorComercialTPA": "110",
            "idTiendaTPA": "1680",
            "CodigoWinPOS": "5990",
            "Responsable": "Alba Lucia Montes Ayala",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Manizales",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "TIENDA LEONISA FUNDADORES MANIZALES",
            "DIRECCION": "Calle 33 B No. 20-03 Local No.155 Centro Comercial Fundadores P.H."
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1800",
            "CodigoWinPOS": "5993",
            "Responsable": "Gloria Stefanie Martinez Plata",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "CARTAGENA DE INDIAS",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA BOCAGRANDE",
            "DIRECCION": "Cra 2 No.6 - 127 / 129 Avenida San Martin"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1801",
            "CodigoWinPOS": "5995",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET PASEO BOLIVAR",
            "DIRECCION": "Calle 35 N° 43 - 51 loc 45 Centro Cial Angeles (nuevo)"
        },
        {
            "idAsesorComercialTPA": "130",
            "idTiendaTPA": "1129",
            "CodigoWinPOS": "5996",
            "Responsable": "Andrea  Ayala Aguas",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bucaramanga",
            "NombreAgencia": "Medellin",
            "Regional": "02",
            "Gerente": "Julian Albeiro Perez Zapata",
            "NOMBRE": "OUTLET BUCARAMANGA",
            "DIRECCION": "Cra 22 No.18 - 50 Barrio San Francisco"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1802",
            "CodigoWinPOS": "5997",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET LEONISA CRA 43 BARRANQUILLA",
            "DIRECCION": "Cra 43 No.35 -38 local 25 Centro Cial Los Angeles (nuevo)"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1803",
            "CodigoWinPOS": "5998",
            "Responsable": "Gloria Stefanie Martinez Plata",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "CARTAGENA DE INDIAS",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "TIENDA LEONISA ZARAGOZA",
            "DIRECCION": "Calle Roman x Calle Colegio (Esquina) No.39 - 52 (CENTRO)"
        },
        {
            "idAsesorComercialTPA": "108",
            "idTiendaTPA": "1804",
            "CodigoWinPOS": "6037",
            "Responsable": "Enna Ruth Vergara Baloco",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Barranquilla",
            "NombreAgencia": "Barranquilla",
            "Regional": "06",
            "Gerente": "Augusto  Alvarez Moreno",
            "NOMBRE": "OUTLET UNICO BARRANQUILLA No.2",
            "DIRECCION": "Calle 74 No.38D - 113 Loc 51 Centro Comercial Unico Barranquilla"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1462",
            "CodigoWinPOS": "9054",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA SAN VICTORINO",
            "DIRECCION": "Calle 13 Nº 10 - 57 av jimenez"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1464",
            "CodigoWinPOS": "9446",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET CRA 15",
            "DIRECCION": "Cra 15 No.93B - 03 / 05"
        },
        {
            "idAsesorComercialTPA": "111",
            "idTiendaTPA": "1465",
            "CodigoWinPOS": "9660",
            "Responsable": "Josefina  Tobar Reyes",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Sogamoso",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA SOGAMOSO",
            "DIRECCION": "Calle 12 No.10 - 86 Loc 102 Centro Comercial Esquina del Sol"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1466",
            "CodigoWinPOS": "9662",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LUMAR BY LEONISA PORTAL 80",
            "DIRECCION": "Calle 80 No.100 - 52 Loc 1-067 Centro Comercial Portal 80"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1467",
            "CodigoWinPOS": "9663",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LAS AMERICAS",
            "DIRECCION": "Avenida las Americas Nº 62 - 22 Local 5"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1468",
            "CodigoWinPOS": "9670",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA UNICENTRO BOGOTÁ",
            "DIRECCION": "Av. 15 No.123 - 30 Loc 2-89 Nivel A Centro Comercial Unicentro Bogotá"
        },
        {
            "idAsesorComercialTPA": "111",
            "idTiendaTPA": "1470",
            "CodigoWinPOS": "9678",
            "Responsable": "Josefina  Tobar Reyes",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Duitama",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA DUITAMA",
            "DIRECCION": "Calle 15 No.16 - 61 Loc 101"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1471",
            "CodigoWinPOS": "9680",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA KENNEDY NO. 2",
            "DIRECCION": "Cra 78B No.38-59 SUR"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1472",
            "CodigoWinPOS": "9682",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA GRAN ESTACION",
            "DIRECCION": "Calle 26 No.62 - 49 Loc 1-94 Centro Comercial Gran Estación PH"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1473",
            "CodigoWinPOS": "9683",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA SALÓN CORSETERO",
            "DIRECCION": "Cra 69 No. 98 A-45 Bodega 3 Local Uno (1) floresta bogota"
        },
        {
            "idAsesorComercialTPA": "126",
            "idTiendaTPA": "1474",
            "CodigoWinPOS": "9684",
            "Responsable": "Nelson Leonardo Ortega Rivera",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA CENTRO MAYOR",
            "DIRECCION": "Av. NQS con Calle 38 Sur Loc 2-033 Piso 2 Centro Comercial Centro Mayor"
        },
        {
            "idAsesorComercialTPA": "125",
            "idTiendaTPA": "1475",
            "CodigoWinPOS": "9685",
            "Responsable": "Federico Lopez Velasquez",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Yopal",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LUMAR BY LEONISA YOPAL",
            "DIRECCION": "CALLE 30 # 26-29/65 LOC 1-065 CCIAL ALCARAVAN"
        },
        {
            "idAsesorComercialTPA": "119",
            "idTiendaTPA": "1476",
            "CodigoWinPOS": "9686",
            "Responsable": "Maria Eva Llanos Fernandez",
            "IdFormato": "2",
            "Formato": "TIENDA LEONISA",
            "CIUDAD": "Neiva",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LEONISA SAN PEDRO NEIVA",
            "DIRECCION": "Centro Comercial San Pedro Plaza II Loc 108 Etapa A"
        },
        {
            "idAsesorComercialTPA": "125",
            "idTiendaTPA": "1477",
            "CodigoWinPOS": "9688",
            "Responsable": "Federico Lopez Velasquez",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Villavicencio",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA ÚNICO VILLAVICENCIO",
            "DIRECCION": "Cra 22 # 8c- 67 Local 46 Barrio el Diamante"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1478",
            "CodigoWinPOS": "9689",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LAS AMERICAS # 2",
            "DIRECCION": "Avenida Americas N° 62 - 84 local 1 - 66 Centro Cial Americas Outlet Factory"
        },
        {
            "idAsesorComercialTPA": "374",
            "idTiendaTPA": "1479",
            "CodigoWinPOS": "9916",
            "Responsable": "Jessica Leonor Ovalle Mora",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "Bogotá",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET RESTREPO",
            "DIRECCION": "Calle 16 Sur No.20 - 28 local 101"
        },
        {
            "idAsesorComercialTPA": "111",
            "idTiendaTPA": "1480",
            "CodigoWinPOS": "9924",
            "Responsable": "Josefina  Tobar Reyes",
            "IdFormato": "4",
            "Formato": "OUTLET",
            "CIUDAD": "FUSAGASUGÁ",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "OUTLET LEONISA FUSAGASUGA",
            "DIRECCION": "Calle.7 No.7 - 62 Loc 101"
        },
        {
            "idAsesorComercialTPA": "117",
            "idTiendaTPA": "1698",
            "CodigoWinPOS": "9953",
            "Responsable": "Ruben Dario Jimenez Serna",
            "IdFormato": "3",
            "Formato": "ZEBRA",
            "CIUDAD": "Cali",
            "NombreAgencia": "Cali",
            "Regional": "04",
            "Gerente": "Luis Rodolfo Rosal Yañez",
            "NOMBRE": "ZEBRA CALIMA",
            "DIRECCION": "Calle 70 No.01 - 41 Centro Comercial La 14 Loc 2-230 A"
        },
        {
            "idAsesorComercialTPA": "111",
            "idTiendaTPA": "1481",
            "CodigoWinPOS": "9977",
            "Responsable": "Josefina  Tobar Reyes",
            "IdFormato": "11",
            "Formato": "LUMAR BY LEONISA",
            "CIUDAD": "Tunja",
            "NombreAgencia": "Bogotá",
            "Regional": "03",
            "Gerente": "Andres Felipe Giraldo Ramirez",
            "NOMBRE": "TIENDA LUMAR BY LEONISA TUNJA",
            "DIRECCION": "Calle 18 No.11 - 22 Loc.4"
        }
    ]
}

